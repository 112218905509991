"use client";
import React, { useEffect, useState } from "react";

import Icons from "../../scss/prh-icon-sprite.svg";

/**
 * @typedef {import("../../types").Bulletin} Bulletin
 */

const defaultText = /** @type {const} */ {
  fi: {
    urlText: "Lue lisää",
    dismiss: "Sulje ilmoitus",
    types: { info: "Tiedote", alert: "Häiriötiedote" },
  },
  sv: {
    urlText: "Mer information",
    dismiss: "Stäng aviseringen",
    types: { info: "Meddelande", alert: "Störningsmeddelande" },
  },
  en: {
    urlText: "Read more",
    dismiss: "Close the notification",
    types: { info: "Bulletin", alert: "Alert" },
  },
};

/**
 * If using environment variables directly, use this instead of fetching config
 * Uncomment the following block and remove the "fetchConfig" useEffect &
 * config state, and `config` dependency from the "fetchData" useEffect
 */
const config = {
  baseUrl: process.env.REACT_APP_PATENTS_COMMUNICATION_URL,
  serviceId: process.env.REACT_APP_PATENTS_COMMUNICATION_ID,
};

/**
 * Component to display bulletin notifications
 *
 * @typedef {Object} Props
 * @prop {function} [onDismiss] - Function to call when alert is dismissed
 * @prop {string} [dismissButtonAriaLabel] - Aria label for the dismiss button
 * @prop {string} [lang="fi"] - Language for the bulletin
 * @prop {string} [className] - Additional class names for the alert
 *
 * @param {Props} props - The properties object.
 * @returns {React.ReactNode}
 */
const Bulletins = (props) => {
  const {
    onDismiss = () => {},
    dismissButtonAriaLabel,
    lang = "fi",
    className,
  } = props;

  /**
   * State to manage bulletin messages and their visibility
   * @type {ReturnType<typeof useState<Bulletin[]>>}
   */
  const [bulletins, setBulletins] = useState();

  /**
   * @type {React.MouseEventHandler<HTMLButtonElement>}
   */
  const handleDismiss = (e) => {
    e.currentTarget.closest(".alert")?.classList.remove("show");
    onDismiss();
  };

  /** Fetch data */
  useEffect(() => {
    let ignore = false;

    /**
     * @param {unknown} data
     * @returns {data is Bulletin[]}
     */
    const assertResponse = (data) => {
      return (
        Array.isArray(data) &&
        data.every((item) => {
          if (typeof item !== "object") return false;
          if (typeof item.id !== "string") return false;
          if (typeof item.type !== "string") return false;
          if (!Array.isArray(item.translations)) return false;

          return item.translations.every(
            (/** @type {Record<string, any>} */ translation) => {
              if (typeof translation.language !== "string") return false;
              if (!("title" in translation)) return false;
              if (!("body" in translation)) return false;
              if (!("url" in translation)) return false;
              return true;
            }
          );
        })
      );
    };

    const fetchData = async () => {
      try {
        if (!config?.baseUrl || !config?.serviceId) return;
        const { baseUrl, serviceId } = config;
        const res = await fetch(`${baseUrl}/services/${serviceId}/bulletins`, {
          mode: "cors",
          headers: { "Access-Control-Allow-Origin": "*" },
        });

        if (!res.ok) {
          console.error("Failed to fetch bulletins");
          return;
        }

        const data = await res.json();
        if (!assertResponse(data)) {
          console.error("Invalid response data for bulletins");
          return;
        }

        const sortedBulletins = data
          .filter((bulletin) => {
            const now = new Date();
            const startDateTime = new Date(bulletin.startDateTime);
            const endDateTime = bulletin.endDateTime
              ? new Date(bulletin.endDateTime)
              : null;
            return startDateTime < now && (!endDateTime || endDateTime > now);
          })
          .sort((a, b) => {
            const aDate = new Date(a.startDateTime);
            const bDate = new Date(b.startDateTime);
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
          });

        if (!ignore) {
          setBulletins(sortedBulletins);
        }
      } catch (error) {
        console.error("Failed to fetch bulletins", error);
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, []);

  /**
   * @type {React.TransitionEventHandler<HTMLDivElement>}
   */
  const onTransitionEnd = (e) => {
    e.currentTarget.closest(".alert")?.remove();
  };

  if (!Array.isArray(bulletins)) return null;

  return (
    <div className="d-flex flex-column">
      {bulletins?.map(({ id, type, translations }) => {
        const bulletinType = type?.toLowerCase() ?? "info";

        const classes = ["alert", "alert-dismissible", "fade", "show", "mb-0"];
        classes.push(bulletinType === "alert" ? "alert-danger" : "alert-info");
        if (className) classes.push(className);

        const defaultTranslation = translations.find(
          ({ language }) => language === "fi"
        );
        const translation =
          translations.find(
            ({ language, title }) => language === lang && !!title
          ) ?? defaultTranslation;

        if (!translation || !translation.title) return null;

        return (
          <div
            key={id}
            role="alert"
            className={classes.join(" ")}
            onTransitionEnd={onTransitionEnd}
          >
            {/* biome-ignore lint/a11y/useSemanticElements: DS styles conflict when using correct semantic elements */}
            <div
              role="region"
              aria-labelledby={`label-${id}-${translation.language}`}
              aria-describedby={`desc-${id}-${translation.language}`}
            >
              {bulletinType === "alert" && (
                <svg
                  className="prh-icon prh-icon--orange"
                  aria-labelledby={`bulletin-${bulletinType}-${id}-${translation.language}`}
                  role="img"
                  focusable="true"
                  tabIndex={0}
                >
                  <title
                    id={`bulletin-${bulletinType}-${id}-${translation.language}`}
                  >
                    {defaultText[lang]?.types.alert ||
                      defaultText.fi?.types.alert}
                  </title>
                  <use xlinkHref={`${Icons}#alert-circle`} />
                </svg>
              )}
              {bulletinType === "info" && (
                <svg
                  className="prh-icon"
                  aria-labelledby={`bulletin-${bulletinType}-${id}-${translation.language}`}
                  role="img"
                  focusable="true"
                  tabIndex={0}
                >
                  <title
                    id={`bulletin-${bulletinType}-${id}-${translation.language}`}
                  >
                    {defaultText[lang]?.types.info ||
                      defaultText.fi?.types.info}
                  </title>
                  <use xlinkHref={`${Icons}#info`} />
                </svg>
              )}

              <h4
                className="alert-heading"
                id={`label-${id}-${translation.language}`}
              >
                {translation.title}
              </h4>

              {translation.body && (
                <p className="small" id={`desc-${id}-${translation.language}`}>
                  {translation.body}
                </p>
              )}

              {translation.url && (
                <a
                  href={translation.url}
                  className="d-inline-flex align-items-center btn-tertiary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translation.urlText ||
                    defaultText[lang]?.urlText ||
                    defaultText.fi?.urlText}
                  <svg
                    aria-hidden="true"
                    className="prh-icon prh-icon--xs"
                    style={{
                      position: "relative",
                      left: "0.25rem",
                      height: "1rem",
                      width: "1rem",
                    }}
                  >
                    <use xlinkHref={`${Icons}#external-link`} />
                  </svg>
                  <span className="visually-hidden sr-only">
                    {" "}
                    (avautuu uuteen ikkunaan)
                  </span>
                </a>
              )}

              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label={
                  dismissButtonAriaLabel ||
                  defaultText[lang]?.dismiss ||
                  defaultText.fi?.dismiss
                }
                onClick={handleDismiss}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { Bulletins, defaultText };
