import React, { useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import UseWindowSize from "../../../customHooks/useWindowSize";
import ResultSorter from "./ResultSorter";
import PatentStatusFilter from "./PatentStatusFilter";
import PatentTypeFilter from "./PatentTypeFilter";
import { bootStrapGrid } from "../../../constants/constants";
import PublicationTypeFilter from "./PublicationTypeFilter";

const SortersAndFilters = (props) => {
  const { translate } = useContext(I18nContext);

  const size = UseWindowSize();

  const onStatusFilterChange = (dossierStatus) => {
    const newParams = { ...props.searchParams, dossierStatus };
    props.fetchSearchResults(newParams);
  };

  const onTypeFilterChange = (patentTypes) => {
    const newParams = { ...props.searchParams, patentTypes };
    props.fetchSearchResults(newParams);
  };

  const onPublicationTypeFilterChange = (publicationTypes) => {
    const newParams = { ...props.searchParams, publicationTypes };
    props.fetchSearchResults(newParams);
  };
  
  const singleColumnView = (
    <Row>
      <Col className="pb-1" xs={12}>
        <ResultSorter
          setShowItems={props.setShowItems}
          data={props.data}
          sorter={props.sorter}
          onChangeSorter={props.onChangeSorter}
        />
      </Col>
      <Col className="pb-1" xs={12}>
        <PatentStatusFilter
          changeFilters={onStatusFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>
      <Col className="pb-1" xs={12}>
        <PatentTypeFilter
          changeFilters={onTypeFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>
      {/*<Col xs={12}>
        <PublicationTypeFilter
          changeFilters={onPublicationTypeFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>*/}
    </Row>
  );
  const doubleColumnView = (
    <Row>
      <Col className="pr-1" md={6}>
        <div className="pb-1">
          <ResultSorter
            setShowItems={props.setShowItems}
            data={props.data}
            sorter={props.sorter}
            onChangeSorter={props.onChangeSorter}
          />
        </div>
        <PatentTypeFilter
          changeFilters={onTypeFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>
      <Col className="pl-1" xs={12} md={6}>
        <div className="pb-1">
          <PatentStatusFilter
            changeFilters={onStatusFilterChange}
            searchParams={props.searchParams}
            setSearchParams={props.setSearchParams}
          />
        </div>
        {/*<PublicationTypeFilter
          changeFilters={onPublicationTypeFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />*/}
      </Col>
    </Row>
  );
  return (
    <Container
      role="region"
      aria-label={translate("sort_and_filter_options")}
      className="p-1"
      style={{ backgroundColor: "#d9d9d9" }}
    >
      {size.width < bootStrapGrid.sm || size.width >= bootStrapGrid.lg
        ? singleColumnView
        : doubleColumnView}
    </Container>
  );
};

export default SortersAndFilters;
