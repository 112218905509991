"use client";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import Icons from "../../scss/prh-icon-sprite.svg";

/**
 * @typedef {import("../../types").Survey} Survey
 */

/**
 * Default texts for the survey modal
 * @type {Record<'fi' | 'sv' | 'en' | string, Record<'openSurvey' | 'skip' | 'dismiss', string>>}
 */
const defaultText = {
  fi: {
    openSurvey: "Avaa kysely",
    skip: "Ohita",
    dismiss: "Sulje ilmoitus",
  },
  sv: {
    openSurvey: "Gå till enkäten",
    skip: "Stäng",
    dismiss: "Stäng aviseringen",
  },
  en: {
    openSurvey: "Go to the survey",
    skip: "Close",
    dismiss: "Close the notification",
  },
};

/**
 * If using environment variables directly, use this instead of fetching config
 * Uncomment the following block and remove the "fetchConfig" useEffect &
 * config state, and `config` dependency from the "fetchData" useEffect
 */
const config = {
  baseUrl: process.env.REACT_APP_PATENTS_COMMUNICATION_URL,
  serviceId: process.env.REACT_APP_PATENTS_COMMUNICATION_ID,
};

/**
 * SurveyModal component displays a modal with survey information.
 *
 * @typedef {Object} SurveyModalProps
 * @prop {string} [lang="fi"] - Language for the bulletin
 * @prop {string} [className] - Additional class names for the alert
 * @prop {string} [dismissButtonAriaLabel] - Aria label for the dismiss button
 * @prop {function} [onDismiss] - Function to call when survey is skipped
 *
 * @param {SurveyModalProps} props - The properties object.
 * @returns {React.ReactNode}
 *
 * @example
 * <SurveyModal
 *   language="en"
 *   onDismiss={handleDismiss}
 * />
 */
const SurveyModal = (props) => {
  const { lang = "fi", dismissButtonAriaLabel, onDismiss = () => {} } = props;

  const [show, setShow] = useState(false);
  /**
   * State to manage survey data
   * @type {ReturnType<typeof useState<Survey>>}
   */
  const [survey, setSurvey] = useState();

  const skippedStatus = useCallback(
    /**
     * @param {string} id
     * @returns {boolean}
     */
    (id) => {
      if (!config) return false;
      const skippedAt = localStorage.getItem(
        `${config.serviceId}_survey_${id}_skipped_at`
      );
      return !!skippedAt;
    },
    [config]
  );

  /**
   *
   * @param {string} id
   * @returns {void}
   */
  const handleSkip = (id) => {
    if (!config) return;
    const now = new Date();
    localStorage.setItem(
      `${config.serviceId}_survey_${id}_skipped_at`,
      now.toISOString().split("T")?.[0] ?? ""
    );
    setShow(false);
    onDismiss();
  };

  /**
   * @param {string} id
   * @returns {void}
   */
  const handleClose = (id) => {
    if (!config) return;
    const key = `${config.serviceId}_survey_shown`;
    const shown = localStorage.getItem(key)?.split(",") || [];
    shown.push(id);
    localStorage.setItem(key, [...new Set(shown)].join(","));
    setShow(false);
  };

  /** Fetch data */
  useEffect(() => {
    if (survey !== undefined) return;
    let ignore = false;

    /**
     * @param {unknown} data
     * @returns {data is Survey[]}
     */
    const assertResponse = (data) => {
      return (
        Array.isArray(data) &&
        data.every((item) => {
          if (typeof item !== "object") return false;
          if (typeof item.id !== "string") return false;
          if (typeof item.startDateTime !== "string") return false;
          if (item.endDateTime && typeof item.endDateTime !== "string")
            return false;
          if (!Array.isArray(item.translations)) return false;

          return item.translations.every(
            (/** @type {Record<string, any>} */ translation) => {
              if (typeof translation.language !== "string") return false;
              if (!("title" in translation)) return false;
              if (!("body" in translation)) return false;
              if (!("url" in translation)) return false;
              return true;
            }
          );
        })
      );
    };

    const fetchData = async () => {
      if (!config?.baseUrl || !config?.serviceId) return;

      try {
        const { baseUrl, serviceId } = config;
        const result = await fetch(`${baseUrl}/services/${serviceId}/surveys`);
        if (!result.ok) {
          return;
        }

        const data = await result.json();
        if (!assertResponse(data)) {
          console.error("Invalid response data");
          return;
        }

        const sortedSurveys = data
          .filter((survey) => {
            const now = new Date();
            const startDateTime = new Date(survey.startDateTime);
            const endDateTime = survey.endDateTime
              ? new Date(survey.endDateTime)
              : null;
            return startDateTime < now && (!endDateTime || endDateTime > now);
          })
          .sort((a, b) => {
            const aDate = new Date(a.startDateTime);
            const bDate = new Date(b.startDateTime);
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
          });

        const firstSurvey = sortedSurveys[0];
        if (!firstSurvey) return;

        if (!ignore) {
          setSurvey(firstSurvey);
        }

        const isSkipped = skippedStatus(firstSurvey.id);
        if (!isSkipped) {
          setTimeout(() => setShow(true), firstSurvey.delay);
        }
      } catch (error) {
        console.error("Failed to fetch survey data", error);
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [skippedStatus, survey]);

  if (!survey) return null;

  const defaultTranslation = survey.translations.find(
    ({ language }) => language === "fi"
  );
  const translation =
    survey.translations?.find(
      ({ language, title, body }) => language === lang && !!title && !!body
    ) ?? defaultTranslation;

  if (!translation || !translation.title) return null;

  return (
    <Modal
      show={show}
      onHide={() => handleClose(survey.id)}
      size="lg"
      aria-labelledby={`label-${survey.id}-${translation.language}`}
      aria-describedby={`desc-${survey.id}-${translation.language}`}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title
          id={`label-${survey.id}-${translation.language}`}
          as={"h2"}
        >
          {translation.title}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label={
            dismissButtonAriaLabel ||
            defaultText[lang]?.dismiss ||
            defaultText.fi?.dismiss
          }
          onClick={() => handleClose(survey.id)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body id={`desc-${survey.id}-${translation.language}`}>
        <p>{translation.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-tertiary"
          onClick={() => handleSkip(survey.id)}
        >
          {defaultText[lang]?.skip || defaultText.fi?.skip}
        </button>

        {translation.url && (
          <a
            href={translation.url}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-primary d-inline-flex"
            onClick={() => handleClose(survey.id)}
          >
            {translation.urlText ||
              defaultText[lang]?.openSurvey ||
              defaultText.fi?.openSurvey}
            <svg
              aria-hidden="true"
              className="prh-icon prh-icon--white"
              style={{ marginLeft: "0.25rem" }}
            >
              <use xlinkHref={`${Icons}#external-link`} />
            </svg>
            <span className="visually-hidden sr-only">
              {" "}
              (avautuu uuteen ikkunaan)
            </span>
          </a>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export { SurveyModal };
