import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import BasicInfo from "./infoComponents/BasicInfo";
import DetailInfo from "./infoComponents/DetailInfo";
import { formatDate, findLanguageVersion } from "../../helpers/helpers";
import PageNotFound from "../errors/PageNotFound";
import PaymentInfo from "./infoComponents/PaymentInfo";

const paymentUrl = process.env.REACT_APP_PAYMENT_SERVICE_URL;

const PatentDetails = (props) => {
  const { translate, langCode } = useContext(I18nContext);
  const data = props.patent.data;
  const loading = props.patent.loading;
  const lang = document.documentElement.lang;

  const handlePrint = () => {
    window.print();
  };

  const fetchPatent = props.fetchPatent;
  let path = "patent/" + props.match.params.applicationNumber;

  useEffect(() => {
    fetchPatent(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.applicationNumber]);

  const title = () => {
    return data.applicationType === "SPC"
      ? getSPCTitle()
      : data.patentTitle && data.patentTitle.length > 0
      ? findLanguageVersion(data.patentTitle, lang).title[0]
      : "-";
  };

  const getSPCTitle = () => {
    let title = "-"
    if(data.filingLanguage === 'SV')
      title = data.patentTitle.find((titleObject) => titleObject.language === "SV").title[0];
    else
      title = data.patentTitle.find((titleObject) => titleObject.language === "FI").title[0];
   return title;
  };

  const titleField = (
    <dl className="pb-2">
      <dt className="m-0 p-0">
        {data.applicationType !== "SPC"
          ? translate("patent:resultfield_name")
          : translate("patent:resultfield_spcname")}
      </dt>
      <dd className="datafield-content" style={{ fontSize: "1.2rem" }}>
        {title()}
      </dd>
    </dl>
  );

  const applicationType = () => {
    if (data.applicationNumber.startsWith("EP")) return "ep";
    else if (
      data.applicationNumber.startsWith("L") ||
      data.applicationNumber.startsWith("C")
    )
      return "spc";
    else if (data.applicationNumber.startsWith("U")) return "um";
    else return "pa";
  };
  if (loading) return null;

  if (data.length < 1) {
    const path = window.location.pathname.split("/");
    if (path[3].startsWith("EP")) {
      return (
        <PageNotFound
          title="ep_not_valid_in_finland_title"
          description="ep_not_valid_in_finland_description"
        />
      );
    } else if (
      props.patent.error &&
      props.patent.error[0].errorTitle !== "ep_not_valid_in_finland_title"
    )
      return (
        <PageNotFound
          title="patent_not_found"
          description="patent_not_found_description"
        />
      );

    return null;
  }

  const isPenaltyPay = (identifier) => {
    const penaltyPaymentIdentifiers = ["F042", "F042", "F042"];
    return (
      penaltyPaymentIdentifiers.includes(identifier) ||
      identifier.includes("S0")
    );
  };

  const getPaymentIdentifierTranslation = (identifier) => {
    if (isPenaltyPay(identifier)) return translate("paymenttype.type.penalty");
    return translate(
      "paymenttype.type." + identifier.toLowerCase() + "_" + applicationType()
    );
  };

  const getDueDate = (element) => {
    if (element.limitDate && isLatePayment(element))
      return formatDate(element.limitDate);
    else if (element.dueDate) return formatDate(element.dueDate);
    else return "-";
  };

  const isLatePayment = (payment) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const dueDate = new Date(payment.dueDate);
    dueDate.setHours(0, 0, 0, 0);
    return dueDate < now;
  };

  return (
    <Container>
      <Row className="pt-4 pb-4">
        <Col xs={7} className="pt-2">
          <h2>{translate("patent:details_title")}</h2>
        </Col>
        <Col xs={5} className="d-flex justify-content-end d-print-none">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handlePrint}
          >
            <span className="ml-1 mr-1">
              {translate("patent:details_print_button")}
            </span>
          </button>
        </Col>
      </Row>
      <Row style={{ borderBottom: "1px solid #b1b1b1" }}>
        <Col>{titleField}</Col>
      </Row>
      <Row className="pt-4 pb-4" style={{ borderBottom: "1px solid #b1b1b1" }}>
        <Col>
          <BasicInfo data={data} pathParams={props.pathParams} />
        </Col>
      </Row>
      <Row className="pt-4 pb-4" style={{ borderBottom: "1px solid #b1b1b1" }}>
        <Col>
          <DetailInfo data={data} pathParams={props.pathParams} />
        </Col>
      </Row>
      <PaymentInfo data={data} pathParams={props.pathParams} />
    </Container>
  );
};

export default withRouter(PatentDetails);
