import React, { useState, useContext } from "react";
import "./scss/styles/app.scss";
import MainPage from "./views/MainPage";
import SearchResultPage from "./views/SearchResultPage";
import SearchPage from "./views/SearchPage";
import PatentDetailsPage from "./views/PatentDetailsPage";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import useEndpoint from "./customHooks/useEndpoint";
import useLocalStorage from "./customHooks/useLocalStorage";
import { initialSearchParams } from "./constants/constants";
import RecordalPage from "./views/RecordalPage";
import { I18nContext } from "./i18n";
import { SurveyModal } from "./components/common/SurveyModal";
require("dotenv").config();

const baseUrl = process.env.REACT_APP_API_URL;

function buildRoutePath(path) {
  const noLangInPath = !/\/:lang\??(\/|$)/.test(path);
  return `/:basePath?${noLangInPath ? "/:lang" : ""}${path}/`;
}

function postSearchEndpoint() {
  /* eslint-disable react-hooks/rules-of-hooks */
  return useEndpoint((data) => ({
    url: `${baseUrl}/patent`,
    method: "POST",
    data,
  }));
}

function getSearchEndpoint() {
  /* eslint-disable react-hooks/rules-of-hooks */
  return useEndpoint((path) => ({
    url: `${baseUrl}/${path}`,
    method: "GET",
  }));
}

const App = () => {
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [viewMode, setViewMode] = useState("prhCard");
  const [currentPage, setCurrentPage] = useState(1);
  const [sorter, setSorter] = useState("");
  const [activeLink, setActiveLink] = useLocalStorage("activeLink", "Home");
  const [showItems, setShowItems] = useState([]);

  const onChangeSorter = (sortOrder) => {
    setSorter(sortOrder);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const changeItems = (items) => {
    setShowItems(items);
  };

  const [search, postSearch] = postSearchEndpoint();
  function doSearch(params, path) {
    setCurrentPage(1);
    setShowItems([]);
    setSorter({});
    postSearch(params, path);
  }

  const [patent, getPatent] = getSearchEndpoint();
  function doPatentSearch(path) {
    getPatent(path);
  }

  const [recordal, getRecordal] = getSearchEndpoint();
  function doRecordalSearch(path) {
    getRecordal(path);
  }

  const withSlashOrEmpty = (param) => {
    return param ? `/${param}` : "";
  };

  const supportedRoutes = ["search", "results", "patent", "related"];
  const isSupportedRoute = (route) => supportedRoutes.includes(route);

  const supportedLanguages = ["fi", "sv", "en"];
  const isSupportedLanguage = (lang) => supportedLanguages.includes(lang);

  let basePath = null;
  let lang = null;
  let route = null;
  const { dispatch } = useContext(I18nContext);

  // test for a path which is missing language e.g. /basePath/route or just /basePath
  const isPathWithLanguageMissing = (lang, basePath) =>
    isSupportedRoute(lang) && !isSupportedLanguage(basePath);

  // test to determine if first part in path is basePath rather than language or route
  const isPathStartingWithBasePath = (basePath) =>
    basePath && !isSupportedLanguage(basePath) && !isSupportedRoute(basePath);

  const setPathParams = (params) => {
    // /lang may be first or second in path: /basePath/lang or /lang
    lang = isSupportedLanguage(params.lang)
      ? params.lang
      : isSupportedLanguage(params.basePath)
      ? params.basePath
      : null;

    // route may be first or second in path: /basePath/route or /route, otherwise in path remainder
    route = isSupportedRoute(params.lang)
      ? params.lang
      : isSupportedRoute(params.basePath)
      ? params.basePath
      : null;

    // paths beginning with /basePath, which may be matched as /basePath or as /lang
    basePath =
      isSupportedLanguage(params.lang) ||
      isPathWithLanguageMissing(params.lang, params.basePath) ||
      isPathStartingWithBasePath(params.basePath)
        ? params.basePath
        : !params.basePath && !isSupportedLanguage(params.lang)
        ? params.lang
        : null;
  };

  const determineRedirect = (props) => {
    if (!route && lang) return false;

    let pathRemainder = props.location.pathname;

    for (const pathParam of [lang, basePath, route])
      pathRemainder = pathRemainder.replace(`/${pathParam}`, "");

    return (
      <Redirect
        to={`${withSlashOrEmpty(basePath)}/${lang || "fi"}${withSlashOrEmpty(
          route
        )}${pathRemainder}`}
      />
    );
  };

  const pathParams = {
    getLang: () => lang,

    buildPath: (path) =>
      `${withSlashOrEmpty(basePath)}${withSlashOrEmpty(lang)}${withSlashOrEmpty(
        path
      )}/`,

    updateLanguage: () => {
      if (lang !== "fi" && document.documentElement.lang !== lang) {
        document.documentElement.lang = lang;
        dispatch({ type: "setLanguage", payload: lang });
      }
    },
  };

  return (
    <div>
      <SurveyModal lang={document.documentElement.lang} />
      <Router>
        <Switch>
          <Route
            path={buildRoutePath("/search")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <SearchPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    fetchSearchResults={doSearch}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/results")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <SearchResultPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    searchResult={search}
                    fetchSearchResults={doSearch}
                    onChangePage={onChangePage}
                    currentPage={currentPage}
                    sorter={sorter}
                    onChangeSorter={onChangeSorter}
                    setShowItems={changeItems}
                    showItems={showItems}
                    pathParams={pathParams}
                    {...props}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/related/:applicationNumber")}
            render={(props) => {
              setPathParams(props.match.params);
              return (
                determineRedirect(props) || (
                  <RecordalPage
                    recordal={recordal}
                    fetchRecordal={doRecordalSearch}
                    pathParams={pathParams}
                    patent={patent}
                    fetchPatent={doPatentSearch}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/patent/:applicationNumber")}
            render={(props) => {
              setPathParams(props.match.params);

              return (
                determineRedirect(props) || (
                  <PatentDetailsPage
                    patent={patent}
                    fetchPatent={doPatentSearch}
                    pathParams={pathParams}
                  />
                )
              );
            }}
          />
          <Route
            path={buildRoutePath("/:lang?")}
            render={(props) => {
              setPathParams(props.match.params);
              const redirect = determineRedirect(props);

              if (redirect) return redirect;
              else if (isSupportedLanguage(lang))
                return (
                  <MainPage
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    fetchSearchResults={doSearch}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    pathParams={pathParams}
                  />
                );
              else
                return (
                  <Redirect
                    to={`${withSlashOrEmpty(basePath)}/${
                      document.documentElement.lang
                    }/`}
                  />
                );
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
